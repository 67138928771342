<!--
 * @Description: 客服工作量统计
 * @Author: ChenXueLin
 * @Date: 2022-03-10 09:50:34
 * @LastEditTime: 2022-07-15 16:03:30
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="employeeNo">
              <el-input
                v-model="searchForm.employeeNo"
                placeholder="工号"
                title="工号"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="userName">
              <el-input
                v-model="searchForm.userName"
                placeholder="用户名称"
                title="用户名称"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="frameworkIds">
              <e6-select
                v-model="searchForm.frameworkIds"
                :dataList="selectData"
                placeholder="服务人组织机构"
                title="服务人组织机构"
                :filterable="true"
                :slotTemplate="'tree'"
                :multiple="false"
                :issingleleaf="false"
                ref="t_select"
                class="treeSelect"
              ></e6-select>
            </el-form-item>
            <el-form-item class="search-item--2" prop="serverTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="创建时间"
                ref="effectiveDatePicker"
                v-model="searchForm.serverTime"
                title="创建时间"
                :picker-options="pickerOptions('searchForm.serverTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="创建时间（始）"
                end-placeholder="创建时间（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template v-slot:operateAction>
          <i class="e6-icon-export_line" title="导出" @click="handleExport"></i>
        </template>
      </table-title>
      <!-- 搜索头部 end -->
      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          class="elTable"
          ref="elTable"
        >
          <el-table-column
            label="工号"
            prop="employeeNo"
            width="160"
            header-align="center"
            align="center"
            fixed
          >
          </el-table-column>
          <el-table-column
            label="姓名"
            prop="serverName"
            width="160"
            header-align="center"
            align="center"
          >
          </el-table-column>
          <el-table-column
            :label="option.className"
            header-align="center"
            align="center"
            v-for="(option, index) in optionsList"
            :key="index"
          >
            <el-table-column
              v-for="(item, index) in option.secondClass"
              :key="index"
              width="160"
              :label="item.className"
              align="center"
              :prop="item.classNameCode"
            ></el-table-column>
          </el-table-column>
          <el-table-column
            label="投诉建议"
            prop="complaintsSuggestions"
            width="160"
            header-align="center"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="月度回访"
            prop="monthlyReview"
            width="160"
            header-align="center"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="维护回访"
            prop="maintenanceVisit"
            width="160"
            header-align="center"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="其他（沟通/调试）"
            prop="otherCommunicationDebug"
            width="160"
            header-align="center"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="其他(短信/转接)"
            prop="otherSmsTransfer"
            width="160"
            header-align="center"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="业务办理"
            prop="businessHandling"
            width="160"
            header-align="center"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="数据查询"
            prop="dataQuery"
            width="160"
            header-align="center"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="其他（无效服务）"
            prop="invalidService"
            width="160"
            header-align="center"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="对接咨询"
            prop="jointConsultation"
            width="160"
            header-align="center"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="有效服务"
            prop="effectiveService"
            width="160"
            header-align="center"
            align="center"
          >
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section" ref="paginationWrapper">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import {
  exportCustomerReport,
  getCustomerReportList,
  getFrameworkTree
} from "@/api";
import { printError } from "@/utils/util";
import { exportData } from "@/utils/download";
export default {
  name: "workloadNew",
  components: {},
  data() {
    return {
      selectData: [],
      loading: false,
      optionsList: [
        {
          className: "标准服务",
          secondClass: [
            {
              className: "热线服务",
              classNameCode: "standardServiceHotLine"
            },
            {
              className: "邮件服务",
              classNameCode: "standardServiceEmail"
            },
            {
              className: "在线服务",
              classNameCode: "standardServiceOnLine"
            }
          ]
        },
        {
          className: "生态伙伴",
          secondClass: [
            {
              className: "热线服务",
              classNameCode: "ecologicalPartnerHotLine"
            },
            {
              className: "邮件服务",
              classNameCode: "ecologicalPartnerEmail"
            },
            {
              className: "在线服务",
              classNameCode: "ecologicalPartnerOnLine"
            }
          ]
        },
        {
          className: "增值服务",
          secondClass: [
            {
              className: "热线服务",
              classNameCode: "valueAddServiceHotLine"
            },
            {
              className: "邮件服务",
              classNameCode: "valueAddServiceEmail"
            },
            {
              className: "在线服务",
              classNameCode: "valueAddServiceOnLine"
            }
          ]
        }
      ],
      tableData: [],
      searchForm: {
        employeeNo: "",
        userName: "",
        frameworkIds: [],
        serverTime: [],
        startTime: "",
        endTime: "",
        pageSize: 10,
        pageIndex: 1
      },
      total: 0
    };
  },
  mixins: [base, listPage, listPageReszie],
  computed: {},
  watch: {
    // 服务时间
    "searchForm.serverTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.serverTime = this.searchForm.serverTime.reverse();
        }
        this.searchForm.startTime = val[0] || "";
        this.searchForm.endTime = val[1] || "";
      }
    }
  },
  methods: {
    async initData() {
      try {
        this.loading = true;
        let promiseList = [getFrameworkTree()];
        let [frameworkRes] = await Promise.all(promiseList);
        //部门下拉框
        this.handleTreeData(frameworkRes.data.children);
      } catch (error) {
        printError(error);
      } finally {
        this.selectLoading = false;
      }
    },
    //处理树形结构
    handleTreeData(data) {
      // 第二种 修改涉及级联选择器 最后一个空白的情况
      // 循环遍历json数据
      for (var i = 0; i < data.length; i++) {
        if (data[i].personSum) {
          data[i]["label"] = data[i].name + "(" + data[i].personSum + ")";
        } else {
          data[i]["label"] = data[i].name;
        }
        if (data[i].children && data[i].children.length) {
          this.handleTreeData(data[i].children);
        }
      }
      this.selectData = data;
    },
    //获取数据
    async queryList() {
      try {
        this.loading = true;
        let res = await getCustomerReportList(this.searchForm);
        this.tableData = this.getFreezeResponse(res, {
          path: "data.array"
        });
        this.total = this.getFreezeResponse(res, {
          path: "data.totalRecords"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //导出
    handleExport() {
      exportData(this, exportCustomerReport);
    }
  },
  created() {
    this.initData();
    this.queryList();
  }
};
</script>
<style lang="scss" scoped></style>
